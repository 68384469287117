import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Drawer from "../elements/drawer"
import { linkResolver } from "../../util"
import { CgClose } from "react-icons/cg"
import PopularTopics from "./popular-topics"

const PageTitle = ({
  page_title_header,
  page_title_intro,
  topics,
  largeTitle = false,
}) => {
  const [topicsVisible, setTopicsVisible] = useState(false)
  const { allSanityArticleType } = useStaticQuery(
    graphql`
      query {
        allSanityArticleType {
          nodes {
            id
            article_type_title
            slug {
              current
            }
          }
        }
      }
    `
  )
  const topicLinksMobile = allSanityArticleType.nodes.map(topic => (
    <div className="column is-half" key={topic.slug.current}>
      <Link
        className="popular-topics__link-btn--mobile"
        to={linkResolver({ _type: "article_type", slug: topic.slug.current })}
      >
        {topic.article_type_title}
      </Link>
    </div>
  ))
  return (
    <section className="section page-title has-padding-bottom-0">
      <div className="container">
        <div className="columns">
          <div className="column is-10-tablet is-offset-1-tablet is-half-desktop is-offset-3-desktop">
            <h1
              className={`title ${
                largeTitle ? "title--large" : ""
              } has-text-centered`}
            >
              {page_title_header}
            </h1>
            <p className="sub-header has-text-centered">{page_title_intro}</p>
          </div>
        </div>
        <div className="is-hidden-tablet has-text-centered">
          <button
            className="topics-mobile-btn"
            onClick={() => setTopicsVisible(true)}
          >
            Topics
          </button>
        </div>
        <Drawer visible={topicsVisible}>
          <nav className="navbar is-hidden-tablet" style={{ marginTop: 60 }}>
            <div className="navbar__close-icon-wrapper">
              <div
                style={{ margin: "10px 10px", fontSize: "1.5rem" }}
                onClick={() => setTopicsVisible(false)}
              >
                <CgClose />
              </div>
            </div>
            <div style={{ margin: "0 20px" }}>
              <div className="columns is-mobile is-multiline">
                {topicLinksMobile}
              </div>
            </div>
          </nav>
        </Drawer>
        {topics && topics.topics.length > 0 && (
          <div className="columns">
            <div className="column">
              <PopularTopics {...topics} />
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
export default PageTitle
