import React from "react"
import Product from "../dynamic-components/product/product"
import PageTitle from "../dynamic-components/page-title"
import RecipeTeaser from "../dynamic-components/recipe-teaser"

const CollectionStructureComponents = ({ data }) => {
  const displayData = data.allSanityProduct.nodes
  const withRecipe = []

  displayData.forEach((item, i) => {
    withRecipe.push(item)
    if (i === 1 || (i === displayData.length - 1 && displayData.length < 2)) {
      if (data.sanityCollection.featured_recipe) {
        withRecipe.push(data.sanityCollection.featured_recipe)
      }
    }
  })

  return (
    <>
      <PageTitle
        page_title_header={data.sanityCollection.collection_intro_title}
        page_title_intro={data.sanityCollection.collection_intro_text}
      />
      <section className="section">
        <div className="container">
          <div
            className="columns is-mobile is-multiline"
            style={{ marginBottom: "30px" }}
          >
            {withRecipe.map((item, i) => {
              if (item.hasOwnProperty("recipe_title")) {
                return (
                  <div
                    key={`${item.recipe_title}_${i}`}
                    className="column is-full-mobile is-half-tablet"
                  >
                    <RecipeTeaser
                      slug={item.slug.current}
                      asset={item.recipe_image_image}
                      title={item.recipe_title}
                    />
                  </div>
                )
              } else {
                return (
                  <div
                    key={item._id}
                    className="column is-half-mobile is-one-quarter-tablet"
                  >
                    <Product {...item} collection_page />
                  </div>
                )
              }
            })}
          </div>
        </div>
      </section>
    </>
  )
}

export default CollectionStructureComponents
